import React from 'react';
import About from '../../components/about';

const i18n = {
  cv: 'CV',
  description: (
    <p>
      Merhaba! Ben Alperen.
      <br/>Uzun uzun kim olduğumdan bahsetmek istemiyorum.
      <br/>Site içinde kaybolursanız, beni daha yakından tanıyabilirsiniz.
      <br/><br/>Beyaz tavşanı takip edin.
    </p>
  ),
  works: 'Çalışmalarım',
};

export default (props) => <About i18n={i18n} {...props} />;
